import { SectionSubheader } from '@components/Layouts';
import React, { FC, ReactNode } from 'react';
import Fade from 'react-reveal/Fade';

interface Props {
  className?: string;
  overlayClassName?: string;
  subheaderClassName?: string;
  children?: ReactNode;
  title: string;
  description: string;
  subTitle: string;
  imageAnimationProps?: any;
  contentAnimationProps?: any;
  reverse?: boolean;
}

const PhoneOverlay: FC<{ className?: string }> = ({ className = '' }) => (
  <div className={`rounded-lg absolute bottom-0 left-0 right-0 ${className}`} />
);

export const AboutTheAppItem: FC<Props> = ({
  contentAnimationProps,
  children,
  subheaderClassName,
  className,
  overlayClassName,
  subTitle,
  title,
  description,
  reverse,
}) => {
  const layoutClass = reverse ? 'flex-col lg:flex-row-reverse' : 'flex-col lg:flex-row';

  return (
    <div className={`flex flex-col ${layoutClass} justify-between items-center ${className}`}>
      <div className="w-full lg:w-6/12 relative flex justify-center">
        <div className="z-10 -ml-16">
          <Fade top>{children}</Fade>
        </div>

        <PhoneOverlay className={`rounded-lg h-60 ${overlayClassName}`} />
      </div>

      <div className="w-full lg:w-5/12 lg:px-10 mt-10 lg:mt-0">
        <Fade {...contentAnimationProps}>
          <div>
            <SectionSubheader className={subheaderClassName} title={subTitle} />
            <h3 className="mt-3 mb-6">{title}</h3>
            <p className="font-medium text-lg text-ocean-blue">{description}</p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AboutTheAppItem;
