import { AboutTheAppItem } from '@components/AboutTheApp';
import { AppStoreBtn, PlayStoreBtn } from '@components/Buttons';
import { SeoHeader } from '@components/Common';
import { AppDownload, AppDownloadSection } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';

const AboutApp = () => {
  return (
    <>
      <div>
        <NavigationHeader />

        <section className="pt-12 lg:pt-20 bg-light-gray">
          <div className="container mx-auto pb-8 lg:pb-16 px-8 xl:px-16">
            <span className="font-bold text-accent-green block">App for family caregivers</span>
            <h1 className="mt-3 mb-0 text-4xl lg:text-6xl">
              Your place to stay connected, share <br /> information, and get help from <br /> family and friends
            </h1>
          </div>

          <div className="container mx-auto lg:pb-16 flex flex-col lg:flex-row justify-between lg:pr-16">
            <div className="w-full relative lg:w-6/12 lg:mr-8">
              <div className="overflow-hidden lg:absolute top-0 left-0 bottom-0 right-0">
                <StaticImage quality={100} src="./assets/initial.png" alt="App for personal & family caregivers" />
              </div>
            </div>

            <div
              className="w-full lg:hidden py-12 flex flex-col items-center justify-center"
              style={{ background: 'rgba(208, 235, 235, 0.5)' }}
            >
              <h3 className="px-8 mt-0 mb-8 text-center">Download the CircleOf App for Free</h3>

              <div className="flex flex-row px-2">
                <PlayStoreBtn className="mr-2" />
                <AppStoreBtn />
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-8 lg:pr-0 lg:pl-8">
              <div className="hidden lg:block">
                <AppDownload qrSubtitle="Download the CircleOf App for Free" qrLayout="vertical" />
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-white">
          <Fade top>
            <h2 className="text-center mt-0 mb-12 px-3 lg:px-0 lg:mb-32 text-4xl lg:text-5xl">Popular Features</h2>
          </Fade>

          <div className="container mx-auto px-8 lg:max-w-5xl">
            <AboutTheAppItem
              contentAnimationProps={{
                right: true,
              }}
              overlayClassName="bg-accent-green"
              title="Organize care and get extra help"
              description="Gather support by inviting friends, family, professional caregivers, and anyone else who can help get things done and provide encouragement throughout the care journey."
              subTitle="Communities & Support"
            >
              <StaticImage height={482} src="./assets/1.png" alt="Organize care and get extra help" />
            </AboutTheAppItem>

            <AboutTheAppItem
              contentAnimationProps={{
                left: true,
              }}
              reverse
              className="mt-16 lg:mt-32"
              overlayClassName="bg-seafoam"
              title="Manage caregiving tasks in one place"
              description={
                `
              Keeping track of doctor's appointments and the daily to-do list is simple with our task ` +
                `management features. Your care team can see what's coming up - inside the app, and on your "real" ` +
                `calendar. To get help with rides to appointments, grocery shopping, errands, medication pick up, ` +
                `dog walking, child care, and more, just assign a task to your team and get notifications when ` +
                `the task is accepted.`
              }
              subTitle="Task Management"
            >
              <StaticImage height={482} src="./assets/2.png" alt="Manage caregiving tasks in one place" />
            </AboutTheAppItem>

            <AboutTheAppItem
              contentAnimationProps={{
                right: true,
              }}
              className="mt-16 lg:mt-32"
              overlayClassName="bg-accent-blue"
              title="Collaborate with group messaging"
              description="Getting lost in group text threads? See all of your caregiving communications in one place
            with a flexible, private chat function that lets you manage how to share important information,
            as well as photos and special moments."
              subTitle="Message"
            >
              <StaticImage height={482} src="./assets/3.png" alt="Collaborate with group messaging" />
            </AboutTheAppItem>

            <AboutTheAppItem
              contentAnimationProps={{
                left: true,
              }}
              reverse
              subheaderClassName="text-accent-green"
              className="mt-16 lg:mt-32"
              overlayClassName="bg-accent-orange"
              title="Connect instantly with free video calls"
              description="When you can’t be in person with your loved ones, use the video call
            feature to share smiles and virtual hugs."
              subTitle="Video Calls"
            >
              <StaticImage height={482} src="./assets/4.png" alt="Connect instantly with free video calls" />
            </AboutTheAppItem>

            <AboutTheAppItem
              contentAnimationProps={{
                right: true,
              }}
              className="mt-16 lg:mt-32"
              overlayClassName="bg-accent-green"
              title="Find out right away when things change"
              description="Keep everyone in the loop on upcoming tasks and volunteer needs with mobile and/or email
            notifications, helping you get the help you need at a moment’s notice. And because things can change
            quickly with caregiving, never miss a beat with real-time updates."
              subTitle="Notifications"
            >
              <StaticImage height={482} src="./assets/5.png" alt="Find out right away when things change" />
            </AboutTheAppItem>

            <AboutTheAppItem
              contentAnimationProps={{
                left: true,
              }}
              reverse
              className="mt-16 lg:mt-32"
              overlayClassName="bg-seafoam"
              title="Get extra help from experts and care supporters"
              description="CircleOf helps you discover new caregiving tools, supplies, and information."
              subTitle="Resources"
            >
              <StaticImage height={482} src="./assets/6.png" alt="Get extra help from experts and care supporters" />
            </AboutTheAppItem>
          </div>
        </section>

        <AppDownloadSection />

        <Footer privacyBannerColor="seafoam" />
      </div>
    </>
  );
};

export default AboutApp;

export const Head = () => <SeoHeader title="About the App" />;
